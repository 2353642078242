.wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;

  .child {
    width: 100%;
    .logoWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .skip {
        > span {
          font-weight: 700;
          font-size: 16px;
        }
      }
    }
    .progressWrapper {
      width: 100%;
      margin: 15px 0px 24px 0px;
      display: flex;
      flex-direction: column;
      .stepInfoWrapper {
        width: 100%;
        margin-bottom: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
