@import "src/assets/scss/colors";

.formWrapper {
  font-family: "Roboto";

  .multiInputWrapper {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 12px;
    background: $white-3;
    .noMargin {
      margin-bottom: 0px !important;
    }
    &.marginBottom {
      margin-bottom: 16px;
    }

    .multiInput {
      width: 100%;
    }
    .inputCheckBox {
      padding: 0px;
      .checkBoxLabel {
        font-family: "Roboto";
        line-height: 140%;
      }
    }
    .signCheckBox {
      align-items: flex-start;
      margin-bottom: 24px;
      padding: 0px;
      &.noMargin {
        margin-bottom: 0px !important;
      }
    }
  }
  .inputWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    &.marginBottom {
      margin-bottom: 8px !important;
    }
    .inputLabel {
      font-family: "Roboto";
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 16px;
      &.marginBottom {
        margin-bottom: 8px;
      }
      > span {
        font-weight: 700;
        line-height: 22px;
      }
    }
  }
  .buttonContainer {
    display: flex;
    margin-left: -20px;
    margin-bottom: -20px;
    position: sticky;
    bottom: 0;
    padding: 16px 24px 24px 24px;
    align-items: center;
    width: calc(100% - 8px);
    gap: 30px;
    background-color: $white-0;
    box-shadow: 0px -2px 4px 0px rgba(29, 0, 65, 0.17);
  }
}
.marginBottom {
  margin-bottom: 24px;
}
.calenderIcon {
  right: 10px;
  cursor: pointer;
}
.boldLabel {
  font-weight: 700;
}
.invalidFeedback {
  text-align: left;
  display: flex;
  color: $orange-0;
  padding: 8px 0px;
  font-size: 14px;
}
.datePickerWrapper {
  border: 1px solid $white-2;
  background: $white-0;
  border-radius: 4px;
  width: 100%;
  margin-top: 8px;

  &.marginBottom {
    margin-bottom: 8px !important;
  }
  .datePicker {
    padding: 9px 16px;
    border: none;
    background: $white-0;
    width: calc(100% - 32px);
    &::placeholder {
      color: $gray-3;
      font-family: "Roboto";
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }

    &.active,
    &.focus,
    &.hover {
      border: none;
    }
  }
}
