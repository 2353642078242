@import "src/assets/scss/colors";

.wrapper {
  .title {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 12px;
    margin-bottom: 32px;
  }

  .marginBottom {
    margin-bottom: 24px;
  }
  .buttonContainer {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin-bottom: 10px;
    .finishLater {
      cursor: pointer;
      color: $purple-1;
      font-size: 18px;
      font-weight: 700;
    }
  }
}
