@import "src/assets/scss/colors";

.cardContainer {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $blue-6;
  background: $white-0;
  width: 100%;
  border-radius: 12px;
  box-shadow: 0px 2px 2px 0px rgba(29, 0, 65, 0.37);
  .upperSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    padding: 14px 0px;
  }
  .upperLeftSection {
    cursor: pointer;
  }
  .lowerSection {
    padding: 15px 0px;
    border-top: 1px solid $blue-7;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    > p {
      font-family: "Roboto";
      font-size: 18px;
      font-weight: 600;
    }
    &.manual {
      flex-wrap: wrap;
      gap: 50px;
    }
  }
}
