body {
  margin: 0;
  padding: 0;
  min-height: 100dvh;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  height: fill-available;
  height: -webkit-fill-available;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "Roboto";
  src: url(../public/assets/fonts/Roboto.ttf);
}
@font-face {
  font-family: "Montserrat";
  src: url(../public/assets/fonts/Montserrat.ttf);
}
@font-face {
  font-family: "Nunito Sans";
  src: url(../public/assets/fonts/NunitoSans.ttf);
}
@font-face {
  font-family: "Inter";
  src: url(../public/assets/fonts/Inter.ttf);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
