@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.label {
  width: 100%;
  text-align: left;
  display: flex;
  margin-bottom: 8px;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.required {
  color: $red-0;
  margin-left: 3px;
}
.wrapper {
  margin-bottom: 24px;
}

.formGroupStyling {
  width: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  border: 1px solid $white-2;
  box-shadow:
    0 1px 3px rgba(50, 50, 93, 0.15),
    0 1px 0 rgba(0, 0, 0, 0.02);

  &:focus,
  &:focus-within,
  &:active,
  &:focus-visible {
    border: 1px solid $blue-5;
  }

  .inputStyling {
    background: $white-0;
    border: none;
    border-radius: 4px;
    padding: 9px 16px;
    outline: none;
    width: 100%;
    color: $black-1;
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;

    &:focus,
    &:active,
    &:focus-visible {
      border: none;
      outline: none;
    }

    &::placeholder {
      color: $gray-3;
      font-family: "Roboto";
      font-size: 14px;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }
  }
}
s ::-webkit-calendar-picker-indicator {
  filter: invert(11%);
}

input[type="date"] {
  color: $white-0;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}

input[type="date"][value=""]::-webkit-datetime-edit {
  color: $gray-4 !important;
}

.requiredLabel {
  margin-left: 3px;
}

.isInvalid {
  outline: none !important;
  border: 1px solid $orange-0 !important;
}

.invalidFeedback {
  text-align: left;
  display: flex;
  color: $orange-0;
  padding: 8px 0px;
  font-size: 14px;
}

.passwordIcon {
  cursor: pointer;
}

.iconStyling {
  border: none;
  border-left: none !important;
  color: $white-0;
  &.rightIcon {
    margin-left: 10px;
  }
  &.leftIcon {
    margin-left: 15px;
  }
  .eyeImage {
    width: 19px;
  }
  svg {
    width: auto;
    height: 19px;
  }
}
