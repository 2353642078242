@import "src/assets/scss/colors";

.drawer {
  width: 100%;
  height: calc(100vh - 80px) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px 16px 0px 0px;
  background: $white-0;
}
