.basicInfoWrapper {
  min-height: calc(100dvh - 180px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .form {
    .fileContainer {
      margin-top: 24px;
    }
    .uploadInfo {
      font-size: 14px;
      line-height: 22px;
    }
  }
}
