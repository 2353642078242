@import "src/assets/scss/colors";

.container {
  height: 16px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid $gray-1;
  background-color: $gray-2;

  .filler {
    height: 100%;
    background-color: $black-0;
    border-radius: inherit;
    transition: width 1s ease-in-out;
  }
}
