@import "src/assets/scss/colors";

.previewFileWrapper {
  min-height: calc(100dvh - 180px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .footer {
    display: flex;
    margin-left: -20px;
    margin-bottom: -20px;
    position: sticky;
    bottom: 0;
    padding: 16px 24px 24px 24px;
    align-items: center;
    width: calc(100% - 8px);
    gap: 30px;
    background-color: $white-0;
    box-shadow: 0px -2px 4px 0px rgba(29, 0, 65, 0.17);
  }
  .pdfViewer {
    height: calc(100vh - 265px);
  }
  .drawer {
    height: calc(100dvh - 65px) !important;
    overflow: scroll;
  }
}
