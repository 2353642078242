@import "src/assets/scss/colors";

.badge {
  border-radius: 20px;
  padding: 0px 8px;
  width: max-content;
  display: flex;
  align-items: center;
  border: 1px solid $blue-2;
  background-color: $blue-1;
  &.success {
    background-color: $green-1;
    border: 1px solid $green-2;
    .verifiedIcon {
      display: block;
      margin-right: 6px;
    }
  }

  .verifiedIcon {
    display: none;
  }
}

.badge p {
  width: max-content;
  font-size: 14px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $black-0;
  margin: 0px;
}
