.container {
  width: 400px;
  .title {
    margin-bottom: 10px;
  }
  .details {
    margin-bottom: 20px;
    span {
      opacity: 0.5;
    }
  }
  .descriptionContainer {
    margin-bottom: 20px;
    .descTitle {
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 10px;
    }
    .descSubTitle {
      font-weight: 400;
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
  .buttonContainer {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }
}
