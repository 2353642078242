@import "src/assets/scss/colors";

.submitWrapper {
  min-height: calc(100dvh - 180px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .title {
    line-height: 22px;
  }

  .marginBottom {
    margin-bottom: 24px;
  }
  .emailWrapper {
    margin: 16px 0px;
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 12px;
    background: $purple-5;
    &.loading {
      align-items: center;
    }
    .radioInput {
      background-color: transparent;
      padding: 0px;
      .radioLabel {
        font-weight: 700;
      }
    }
  }
  .text {
    font-weight: 300;
    line-height: 22px;
    margin-bottom: 24px;
    > span {
      color: $purple-4;
      font-weight: 400;
      line-height: 22px;
      text-decoration-line: underline;
    }
  }
  .mobileOption {
    .checkboxWrapper {
      background: transparent;
      gap: 15px;
      margin-bottom: 24px;
      padding: 0px;
    }
  }
  .verifiedWrapper {
    display: inline-flex;
    padding: 16px 11px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    border-radius: 12px;
    background: $white-3; //#F5F5F6;
    margin-bottom: 25px;
    .verifiedPara {
      font-weight: 300;
      line-height: 140%;
    }
  }
}
.spinner {
  width: 24px;
  height: 24px;
  border: 3px solid $black-0;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
