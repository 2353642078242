/**
* Color
**/

// White
$white-0: #fff;
$white-1: #f2fbff;
$white-2: #d9d9d9;
$white-3: #f5f5f6;
$white-4: #ececec;
$white-5: #e2e2e2;
$white-6: #dadada;

// Black
$black-0: #000;
$black-1: #262626;
$black-2: #111;

// Gray
$gray-0: #dcdcdc;
$gray-1: #ebebeb;
$gray-2: #f7f7f7;
$gray-3: #adb5bd;
$gray-4: #a1a1a1;
$gray-5: #868686;

// Blue
$blue-0: #0072f5;
$blue-1: #e4e6ff;
$blue-2: #2833b2;
$blue-4: #bee3f1;
$blue-5: #4e5e72;
$blue-6: #b1b8c0;
$blue-7: #b1b8bf;

//Yellow
$yellow-0: #f5a524;

//Green
$green-0: #008000;
$green-1: #d1f9de;
$green-2: #4ab986;
$green-3: #009b73;
$green-4: #7ca24b;

//Red
$red-0: #f5365c;
$red-1: #fb140c;

//Orange
$orange-0: #fb6340;

//Purple
$purple-1: #171725;
$purple-2: #1d0041;
$purple-3: #32325d;
$purple-4: #3949ff;
$purple-5: #dce0ff;
