@import "src/assets/scss/colors";

.successWrapper {
  display: flex;
  min-height: calc(100dvh - 40px);
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .logoWrapper {
    width: 100%;
  }
  .successIconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 292px;
    flex-direction: column;
    gap: 16px;
    text-align: center;
  }
  .buttonContainer {
    width: 100%;
  }
}
