@import "src/assets/scss/colors";

.experienceWrapper {
  display: flex;
  flex-direction: column;
  min-height: calc(100dvh - 180px);
  justify-content: space-between;
  .companyCardWrapper {
    margin-bottom: 24px;
    .companyCard {
      margin-bottom: 24px;
    }
  }
}
.guidanceText {
  font-size: 14px;
  line-height: 22px;
  margin-top: 16px;
}
