.wrapper {
  .title {
    line-height: 33.6px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 12px;
    margin-bottom: 32px;
  }
  .details {
    .departmentSummary {
      line-height: 22.4px;
    }
    margin-bottom: 60px;
    ul {
      list-style: none;
      padding-left: 0;
    }
    p,
    li {
      margin-bottom: 24px;
      line-height: 22.4px;
    }
  }
  .buttonContainer {
    padding-bottom: 30px;
  }
}
