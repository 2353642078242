@import "src/assets/scss/colors";

.dialog {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
  z-index: 1000;
  .dialogContent {
    background: $gray-1;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    p {
      margin: 10px 0px 30px 0px;
    }
    .closeBtn {
      background-color: transparent !important;
      text-align: right;
      padding: 0px;
      margin-bottom: 10px;
      font-size: 16px;
      span {
        cursor: pointer;
        border: 1px solid $gray-3;
        border-radius: 8px;
        padding: 8px;
      }
    }
  }
}
