@import "src/assets/scss/colors";
.title {
  margin-bottom: 24px;
}
.formWrapper {
  .form {
    display: flex;
    flex-direction: column;
    .inputWrapper {
      .datePickerWrapper {
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        .datePicker {
          margin-top: 8px;
          display: flex;
          width: calc(100% - 32px);
          padding: 9px 16px;
          align-items: center;
          border-radius: 4px;
          border: 1px solid $white-2;
          background: $white-0;
          color: $black-0;
          font-family: "Roboto";
          font-size: 14px;
          line-height: 22px;
        }
        .invalidFeedback {
          text-align: left;
          display: flex;
          color: $orange-0;
          padding: 8px 0px;
          font-size: 14px;
        }
        .datePicker::placeholder {
          color: $gray-3;
          font-family: "Roboto";
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }
      }
    }
    .buttonWrapper {
      margin-top: 28px;
    }
  }
}
