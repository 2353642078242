@import "src/assets/scss/colors";

.formWrapper {
  height: 100%;
  .title {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 12px;
    margin-bottom: 32px;
  }
  .details {
    display: flex;
    flex-direction: column;
    gap: 28px;
    margin-bottom: 32px;
  }
  .infoSelect {
    .selectTitle {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 16px;
    }
    .optionsWrapper {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      gap: 29px;
      margin-bottom: 32px;
    }
    .buttonContainer {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      .finishLater {
        cursor: pointer;
        color: $purple-1;
        font-size: 18px;
        font-weight: 700;
      }
    }
  }
}
