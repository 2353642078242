@import "src/assets/scss/colors";

.searchContainer {
  width: 100%;
  .searchTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  .searchInputGroup {
    border-radius: 24px;
    border: 1px solid rgba(180, 189, 199, 0.14);
    background: $white-3;
    padding: 2px 0px;
    margin-bottom: 8px;
    .searchInput {
      background-color: transparent;
      &::placeholder {
        color: #262626;
        font-family: "Roboto";
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        opacity: 0.5;
      }
    }
  }
  .loaderWrapper {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    .spinner {
      width: 40px;
      height: 40px;
      border: 3px solid $black-0;
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  .searchList {
    max-height: 350px;
    overflow-y: auto;
    .listItem {
      cursor: pointer;
      border-bottom: 1px solid $white-4;
      padding: 12px;
      font-weight: 500;
      color: $purple-3;
      > span {
        color: $purple-4;
        font-size: 14px;
        font-weight: 600;
        text-decoration-line: underline;
      }
    }
  }
}
