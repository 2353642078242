@import "src/assets/scss/colors";
.dropdown {
  width: 100%;
  position: relative;
  .label {
    font-size: 14px;
    line-height: 22px;
  }
  .select {
    width: 100%;
    display: flex;
    padding: 9px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid $white-5; //#d9d9d9
    background: $white-0;
    transition: height 0.5s ease;
    appearance: none;
    line-height: 22px;
    font-size: 14px;
    cursor: pointer;
    &:focus {
      border: 1px solid $blue-5;
    }
    &:active {
      border: 1px solid $blue-5;
    }
  }
  .customIcon {
    position: absolute;
    top: 45px;
    right: 12px;
    transform: translateY(-50%);
    cursor: pointer;
  }
}
.isInvalid {
  outline: none !important;
  border: 1px solid $orange-0 !important;
}

.invalidFeedback {
  text-align: left;
  display: flex;
  color: $orange-0;
  padding: 8px 0px;
  font-size: 14px;
}
