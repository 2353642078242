$padding: 20px;

.container {
  max-width: 1440px;
  height: calc(100dvh - #{$padding} * 2);
  margin: 0 auto;
  padding: $padding;
  display: flex;
  justify-content: center;
}
