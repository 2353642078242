@import "src/assets/scss/colors";

.wrapper {
  min-height: calc(100dvh - 40px);
  max-width: 1440px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .child {
    .processText {
      margin-top: 27px;
      .subText {
        margin-top: 20px;
        font-weight: 300;
        line-height: 24px;
      }
      .italicText {
        font-style: italic;
        font-weight: 700;
        line-height: 24px;
        margin-top: 24px;
      }
    }
    .whyGetVerified {
      margin-top: 16px;
      border-radius: 12px;
      border: 1px solid $blue-6;
      background: $white-0;
      box-shadow: 0px 2px 2px 0px rgba(29, 0, 65, 0.37);
      padding: 16px;
      max-height: 246px;
      .verifyText {
        margin-top: 12px;
        font-weight: 300;
        line-height: 140%;
      }
    }
    &.summary {
      .auth {
        margin-top: 10px;
        border-radius: 12px;
        background: $white-3;
        padding: 16px 15px 0px 16px;
        line-height: 140%;
        .authHeader {
          margin-bottom: 20px;
        }
        .summaryHeader {
          margin-bottom: 12px;
        }
        .summary {
          max-height: calc(100vh - 410px);
          overflow-y: auto;
        }
      }
    }
  }
  .buttonContainer {
    display: flex;
    justify-content: center;
  }
}
