@import "src/assets/scss/colors";

.formWrapper {
  font-family: "Roboto";
  padding: 0px 24px;
  height: 100%;
  .header {
    display: flex;
    margin: 24px 0px;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 18px;
      font-weight: 700;
    }
  }

  .note {
    margin-bottom: 24px;
    .noteText {
      font-weight: 700;
      margin-bottom: 10px;
    }
  }
  .multiInputWrapper {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 12px;
    background: $white-3;
    margin-bottom: 24px;
    &.noMargin {
      margin-bottom: 0px !important;
    }
    .noMargin {
      margin-bottom: 0px !important;
    }
    .multiInputLabel {
      font-family: "Roboto";
      font-size: 14px;
      font-weight: 400;
      > span {
        font-weight: 700;
        line-height: 22px;
      }
    }
    .multiInput {
      width: 100%;
    }
    .inputCheckBox {
      padding: 0px;
      .checkBoxLabel {
        font-family: "Roboto";
        line-height: 140%;
      }
      .checkBoxBoldLabel {
        font-weight: 700;
        font-family: "Roboto";
      }
    }
    .signCheckBox {
      align-items: flex-start;
      margin-bottom: 24px;
      padding: 0px;
      &.noMargin {
        margin-bottom: 0px !important;
      }
      .checkBoxBoldLabel {
        font-weight: 700;
        font-family: "Roboto";
      }
    }
    .inputContainer {
      display: flex;
      gap: 36px;
    }
    .datePickerWrapper {
      border: 1px solid $white-2;
      background: $white-0;
      border-radius: 4px;
      margin-bottom: 24px;
      width: 100%;

      &.noMargin {
        margin-bottom: 0px !important;
      }
      .datePicker {
        padding: 9px 16px;
        border: none;
        background: $white-0;
        width: calc(100% - 32px);
        &::placeholder {
          color: $gray-3;
          font-family: "Roboto";
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }

        &.active,
        &.focus,
        &.hover {
          border: none;
        }
      }
    }
  }
  .inputWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .inputLabel {
      font-family: "Roboto";
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 16px;
      &.marginBottom {
        margin-bottom: 8px;
      }
      > span {
        font-weight: 700;
        line-height: 22px;
      }
    }
    .inputContainer {
      display: flex;
      gap: 36px;
    }
  }
  .radioGroup {
    margin-bottom: 24px;
    .radioLabel {
      font-weight: 700;
    }
  }
  .datePickerWrapper {
    border: 1px solid $white-2;
    background: $white-0;
    border-radius: 4px;
    margin-bottom: 24px;
    width: 100%;
    .datePicker {
      padding: 9px 16px;
      border: none;
      background: $white-0;
      width: calc(100% - 32px);
      &.active,
      &.focus,
      &.hover {
        border: none;
      }
    }
  }
  .buttonContainer {
    padding-bottom: 30px;
    margin-top: 8px;
  }
}
.marginBottom {
  margin-bottom: 24px;
}
.calenderIcon {
  right: 10px;
  cursor: pointer;
}
.boldLabel {
  font-weight: 700;
}
.invalidFeedback {
  text-align: left;
  display: flex;
  color: $orange-0;
  padding: 8px 0px;
  font-size: 14px;
}
.cursor {
  cursor: pointer;
}
