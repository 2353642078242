@import "src/assets/scss/colors";

.wrapper {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  border-radius: 12px;
  background: $white-3;
  .radioElement {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 12px;
    input[type="radio"] {
      cursor: pointer;
      accent-color: $black-2;
      width: 24px;
      height: 24px;
      margin: 0px;
      accent-color: $white-0;
    }
    input[type="radio"]:checked:after {
      width: 15px;
      height: 15px;
      border-radius: 15px;
      top: -2px;
      left: -1px;
      position: relative;
      background-color: white;
      content: "";
      display: inline-block;
      visibility: visible;
      padding: 0;
      border: 6px solid black;
    }

    .label {
      line-height: 140%;
      width: calc(100% - 24px);
      cursor: pointer;
    }
  }
}
