@import "src/assets/scss/colors";

.buttonStyling {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  padding: 14px 0px;
  width: 100%;
  border-radius: 18px;
  background: $purple-1;
  font-family: "Roboto";
  cursor: pointer;
  color: $white-0;
  font-size: 18px;
  .leftIconStyling {
    margin-right: 10px;
  }

  .rightIconStyling {
    margin-left: 10px;
  }

  border: none;
}

.spinner {
  vertical-align: top;
  height: 20px;
  width: 20px;
  border: 2px solid $white-0;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  margin-left: 5px;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.link {
  display: inline-flex;
  gap: 4px;
}

.linkStyling {
  border: none !important;
}

.md {
  padding: 12px 20px;
}

.sm {
  padding: 7px 12px;
}

.disabled {
  &:disabled {
    opacity: 0.4 !important;
    cursor: default;
    border-radius: 18px;
    background: $white-6;
    box-shadow:
      0px 2px 1px 0px rgba(175, 175, 175, 0.67),
      0px -4px 6px 0px #ccc;
  }
}

//btn-fill

.primary-fill {
  background-color: $purple-1 !important;
  box-shadow:
    0px 2px 1px 0px rgba(29, 0, 65, 0.37),
    0px -4px 6px 0px #1d0041 inset;
  &:hover,
  &:focus,
  &:active {
    box-shadow:
      0px 4px 6px rgba(50, 50, 93, 0.11),
      0px 1px 3px rgba(0, 0, 0, 0.08);
    color: $white-0;
    background-color: $purple-1;
    border: none;
  }
}

.secondary-fill {
  color: $black-0;
  background-color: blue !important;

  &:hover,
  &:focus,
  &:active {
    box-shadow:
      0px 4px 6px rgba(50, 50, 93, 0.11),
      0px 1px 3px rgba(0, 0, 0, 0.08);
    background-color: blue;
    color: $black-0;
    border: none;
  }
  &:disabled {
    color: $black-0;
    border-radius: 18px;
    background: $white-6;
    box-shadow:
      0px 2px 1px 0px rgba(175, 175, 175, 0.67),
      0px -4px 6px 0px #ccc inset;
  }
}

.info-fill {
  background-color: yellow !important;

  &:hover,
  &:focus,
  &:active {
    box-shadow:
      0px 4px 6px rgba(50, 50, 93, 0.11),
      0px 1px 3px rgba(0, 0, 0, 0.08);
    color: $white-0;
    background-color: yellow;
    border: none;
  }
}

.success-fill {
  background-color: green !important;

  &:hover,
  &:focus,
  &:active {
    box-shadow:
      0px 4px 6px rgba(50, 50, 93, 0.11),
      0px 1px 3px rgba(0, 0, 0, 0.08);
    color: $white-0;
    background-color: green;
    border: none;
  }
}

.danger-fill {
  background-color: orange !important;

  &:hover,
  &:focus,
  &:active {
    box-shadow:
      0px 4px 6px rgba(50, 50, 93, 0.11),
      0px 1px 3px rgba(0, 0, 0, 0.08);
    color: $white-0;
    background-color: orange;
    border: none;
  }
}

.warning-fill {
  background-color: orange !important;

  &:hover,
  &:focus,
  &:active {
    box-shadow:
      0px 4px 6px rgba(50, 50, 93, 0.11),
      0px 1px 3px rgba(0, 0, 0, 0.08);
    color: $white-0;
    background-color: orange;
    border: none;
  }
}

//btn-outline

.primary-outline {
  border: 1px solid $purple-1 !important;
  color: $purple-1 !important;
  background-color: $white-0 !important;
  box-shadow: 0px 2px 1px 0px rgba(29, 0, 65, 0.37);
  &:hover,
  &:focus,
  &:active {
    border: 1px solid $purple-1;
    background-color: $purple-1;
    color: $white-0;
  }
}

.secondary-outline {
  border: 1px solid $black-0;
  color: $black-0;
  background-color: $white-0 !important;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid $black-0;
    background-color: $black-0;
    color: $black-0;
    box-shadow: none;
  }
}

.info-outline {
  border: 1px solid yellow !important;
  color: yellow !important;
  background-color: $white-0 !important;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid yellow;
    background-color: yellow;
    color: $white-0;
    box-shadow: none;
  }
}

.success-outline {
  border: 1px solid green !important;
  color: green !important;
  background-color: $white-0 !important;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid green;
    background-color: green;
    color: $white-0;
    box-shadow: none;
  }
}

.danger-outline {
  border: 1px solidorange !important;
  color: orange !important;
  background-color: $white-0 !important;

  &:hover,
  &:focus,
  &:active {
    border: 1px solidorange;
    background-color: orange;
    color: $white-0;
    box-shadow: none;
  }
}

.warning-outline {
  border: 1px solid orange !important;
  color: orange !important;
  background-color: $white-0 !important;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid orange;
    background-color: orange;
    color: $white-0;
    box-shadow: none;
  }
}

.primary-link {
  color: $purple-1;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

.secondary-link {
  color: $black-0;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

.info-link {
  color: yellow;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

.success-link {
  color: green;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

.danger-link {
  color: orange;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

.warning-link {
  color: orange;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

.primary-text {
  outline: none;
  border: none;
  color: $purple-1 !important;
  background-color: $white-0 !important;
  width: auto;
  &:hover,
  &:focus,
  &:active {
    outline: none;
    border: none;
    background-color: $purple-1;
    color: $white-0;
  }
  &.disabled {
    border: none;
    outline: none;
    box-shadow: none;
    cursor: default !important;
  }
}
