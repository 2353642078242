@import "src/assets/scss/colors";

.formWrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  .title {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 12px;
    margin-bottom: 32px;
  }
  .form {
    margin-bottom: 26px;
    .question {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 24px;
    }
    .optionsWrapper {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: row;
      gap: 20px;
      margin-bottom: 32px;
    }
    .invalidFeedback {
      text-align: left;
      display: flex;
      color: $orange-0;
      padding-bottom: 8px;
      font-size: 14px;
    }
    border-bottom: 1px solid $white-5; // #e2e2e2
  }
  .inputWrapper {
    margin-bottom: 50px;
    .dollarIcon {
      color: $black-1;
    }
  }
  .buttonContainer {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin-bottom: 10px;
    .finishLater {
      cursor: pointer;
      color: $purple-1;
      font-size: 18px;
      font-weight: 700;
    }
  }
}
