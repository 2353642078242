@import "src/assets/scss/colors";
.wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  .option {
    user-select: none;
    cursor: pointer;
    background: $white-0;
    border: 1px solid $gray-0;
    color: $gray-0;
    width: 100%;
    border-radius: 10px;
    padding: 12px 32px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
}
