@import "src/assets/scss/colors";

.cardContainer {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  border: 1px solid $blue-6;
  background: $white-0;
  width: 100%;

  .leftSection {
    height: 100%;
    padding: 12px 16px;
    width: 100%;
    border-right: 1px solid $blue-7;
  }
  .rightSection {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    padding: 15px 0px;
  }
}
