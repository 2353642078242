@import "src/assets/scss/colors";

.formWrapper {
  font-family: "Roboto";
  .title {
    color: $black-0;
    font-size: 24px;
    font-weight: 600;
    line-height: 140%;
  }
  .requiredText {
    margin-bottom: 32px;
    margin-top: 12px;
  }

  .info {
    line-height: 140%;
    .infoTitle {
      color: $black-2;
      font-family: "Roboto";
      font-size: 16px;
      font-weight: 700;
      line-height: 140%;
      margin-bottom: 24px;
    }
    .subPoints {
      line-height: 22.4px;
      margin-bottom: 5px;
    }
    .subText {
      margin: 24px 0px;
      .link {
        margin-left: 5px;
        cursor: pointer;
        color: $purple-4;
        text-decoration-line: underline;
      }
    }
    .radioGroup {
      margin-bottom: 24px;
    }
  }
  .form {
    .inputWrapper {
      margin-bottom: 44px;
      .datePickerWrapper {
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        .datePicker {
          margin-top: 8px;
          display: flex;
          width: calc(100% - 32px);
          padding: 9px 16px;
          align-items: center;
          border-radius: 4px;
          border: 1px solid $white-2;
          background: $white-0;
          color: $black-0;
          font-family: "Roboto";
          font-size: 14px;
          line-height: 22px;
          &.isInvalid {
            outline: none !important;
            border: 1px solid $orange-0 !important;
          }
        }
        .invalidFeedback {
          text-align: left;
          display: flex;
          color: $orange-0;
          padding: 8px 0px;
          font-size: 14px;
        }
        .datePicker::placeholder {
          color: $gray-3;
          font-family: "Roboto";
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }
      }
    }
  }
  .buttonContainer {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin-bottom: 10px;
    .finishLater {
      cursor: pointer;
      color: $purple-1;
      font-size: 18px;
      font-weight: 700;
    }
  }
}
