@import "src/assets/scss/colors";

.checkboxStyling {
  display: flex;
  padding: 24px;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: $white-3; //#f5f5f6

  input {
    height: 24px;
    min-width: 24px;
    border: 1px solid $gray-5; //#868686
    box-shadow: none;
    border-radius: 2px;
    cursor: pointer;
  }

  input[type="checkbox"] + label {
    cursor: pointer;
    font-family: "Nunito Sans";
  }

  input[type="checkbox"] {
    accent-color: $black-2;
  }

  &.radio {
    filter: none;
    background-color: transparent;
    border: 2px solid $purple-1; //#171725
    position: relative;
    input[type="radio"] {
      accent-color: $black-2;
    }

    &:before {
      content: " ";
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background: $purple-1;
      position: absolute;
      left: 3px;
      top: 3px;
    }
  }
}

.invalidFeedback {
  display: flex;
  color: $orange-0;
  padding: 8px 12px;
}
.requiredLabel {
  margin-left: 3px;
  font-weight: 700;
}
