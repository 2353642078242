@import "src/assets/scss/colors";

.updateEmailWrapper {
  display: flex;
  min-height: calc(100dvh - 40px);
  flex-direction: column;
  justify-content: space-between;
  .upperWrapper {
    .submitText {
      line-height: 22px;
      margin-bottom: 32px;
      > span {
        font-style: italic;
      }
    }
    .emailWrapper {
      padding: 10px 24px;
      border-radius: 12px;
      background: $purple-5; //#dce0ff
      margin-bottom: 32px;
      cursor: pointer;
      .email {
        font-weight: 500;
        line-height: 22px;
      }
    }
    .verifiedWrapper {
      display: inline-flex;
      padding: 16px 11px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 12px;
      border-radius: 12px;
      background: $white-3; //#F5F5F6;
      .verifiedPara {
        font-weight: 300;
        line-height: 140%;
      }
    }
  }
}
.wrapper {
  .title {
    margin-top: 30px;
    margin-bottom: 24px;
  }
}
