@import "src/assets/scss/colors";

.wrapper {
  height: 100%;
  line-height: 140%;
  .title {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    line-height: 33.3px;
    gap: 12px;
    margin-bottom: 32px;
  }
  .details {
    margin-bottom: 24px;

    .question {
      font-weight: 700;
      margin-bottom: 24px;
    }
    .info {
      margin: 24px 0px;
      p {
        margin: 24px 0px;
      }
    }
    li {
      margin-bottom: 28px;
    }
    .divider {
      text-align: center;
      font-weight: 700;
      .wordWithLine {
        display: inline-block;
        position: relative;
        padding: 0px 10px;
        &::before,
        &::after {
          content: "";
          position: absolute;
          width: 50%;
          height: 1px;
          background-color: $white-5;
          top: 50%;
        }
        &::before {
          right: 100%;
        }
        &::after {
          left: 100%;
        }
      }
    }
  }
  .radioGroup {
    margin-bottom: 24px;
  }
  ol {
    padding: 10px;
    li {
      margin-bottom: 24px;
    }
  }
  .inputWrapper {
    margin-bottom: 44px;

    .checkBoxLabel {
      font-family: "Nunito Sans";
    }
    .datePickerWrapper {
      margin-bottom: 24px;
      display: flex;
      flex-direction: column;
      .datePicker {
        margin-top: 8px;
        display: flex;
        width: calc(100% - 32px);
        padding: 9px 16px;
        align-items: center;
        border-radius: 4px;
        border: 1px solid $white-2;
        background: $white-0;
        color: $black-0;
        font-family: "Roboto";
        font-size: 14px;
        line-height: 22px;
        &.isInvalid {
          outline: none !important;
          border: 1px solid $orange-0 !important;
        }
      }
      .invalidFeedback {
        text-align: left;
        display: flex;
        color: $orange-0;
        padding: 8px 0px;
        font-size: 14px;
      }
      .datePicker::placeholder {
        color: $gray-3;
        font-family: "Roboto";
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
  .buttonContainer {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin-bottom: 10px;
    .finishLater {
      cursor: pointer;
      color: $purple-1;
      font-size: 18px;
      font-weight: 700;
    }
  }
}
