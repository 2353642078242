@import "src/assets/scss/colors";

.typography {
  font-size: var(--size);
  color: var(--color);
  margin: 0;
}

//family
.roboto {
  font-family: "Roboto";
}

.arial {
  font-family: "Arial";
}

.montserrat {
  font-family: "Montserrat";
}

.nunitoSans {
  font-family: "Nunito Sans";
}

//weight
.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semibold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.black {
  font-weight: 900;
}
