@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.uploadButtonWrapper {
  width: 100%;
  border-radius: 12px;
  border: 1px solid $blue-4;
  background: $white-1;
  cursor: pointer;
}

.uploadButton {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 17px;

  label {
    color: $black-2;
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }
  .leftContainer {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .rightContainer {
    svg {
      path {
        fill: $black-0;
      }
    }
    input {
      position: absolute;
      display: none;
    }
    display: flex;
    align-items: flex-end;
    gap: 6px;
    > label {
      font-family: "Montserrat";
      cursor: pointer;
    }
  }
  .loader {
    color: $green-3;
  }
}

.invalidFeedback {
  text-align: left;
  display: flex;
  color: $orange-0;
  padding: 8px 0px;
  font-size: 14px;
}
